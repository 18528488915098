import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { showAvatar, transformDate } from '../../helpers';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import TextField from '@material-ui/core/TextField';
// core components
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import Card from 'components/Card/Card.jsx';
import CardAvatar from 'components/Card/CardAvatar.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import Success from '../../components/Typography/Success';
import Warning from '../../components/Typography/Warning';
import Danger from '../../components/Typography/Danger';
import Snackbar from '../../components/Snackbar/Snackbar';
import DeleteAdminDialog from '../../components/Dialog/DeleteAdminDialog';

import { UsersRoutes, Profile } from '../../client';
// NOTE: hide subscription information till it needs
// import moment from 'moment';

const styles = {
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
  cardCategory: {
    textTransform: 'uppercase',
    margin: '16px 0 8px',
    fontWeight: '600',
  },
  description: {
    textTransform: 'uppercase',
    margin: '16px 0 8px',
  },
  socialCat: {
    firstLetter: {
      textTransform: 'capitalize',
    },
    margin: '8px 0 4px',
  },
  social: {
    margin: '8px 0 4px',
  },
};

const UserProfile = props => {
  const [currentUser, setUser] = useState({});
  const [message, setMessage] = useState('');
  const [tc, setAlert] = useState(false);
  const [editUser, setEdit] = useState({});
  const [updateButton, setUpdateButton] = useState(false);
  const { classes, location, admin } = props;

  const [dialog, setDialog] = useState(false);
  const dialogMessage = 'Do you want to delete this user?';
  const action = 'delete';
  const item = 'user';

  const showNotification = () => {
    setAlert(true);
    setTimeout(function () {
      setAlert(false);
    }, 3000);
  };

  useEffect(() => {
    if (location.state && currentUser._id !== location.state._id) {
      UsersRoutes.getUser(location.state._id).then(user =>
        setUser(transformDate(user)),
      );
    } else if (admin && currentUser._id !== admin._id && !location.state) {
      setUser(transformDate(admin));
    } else if (!admin && !currentUser._id && !location.state) {
      Profile.getProfile().then(profile => setUser(transformDate(profile)));
    }
    if (!updateButton) {
      setEdit({
        email: currentUser.email,
        phone: currentUser.phone,
        country: currentUser.country,
        social: currentUser.social,
      });
    }
  }, [currentUser]);

  const handleChange = ({ target }) => {
    const { value, name } = target;
    setUpdateButton(true);
    if (name === 'email' || name === 'phone' || name === 'country') {
      setEdit(prevState => ({ ...prevState, [name]: value }));
      if (value === currentUser[name]) setUpdateButton(false);
    }

    if (
      name === 'facebook' ||
      name === 'google' ||
      name === 'twitter' ||
      name === 'youtube' ||
      name === 'paypal'
    ) {
      setEdit(prevState => ({
        ...prevState,
        social: { ...prevState.social, [name]: value },
      }));
      if (value === currentUser.social[name]) setUpdateButton(false);
    }
  };

  const handleSetAdmin = async e => {
    e.preventDefault();
    try {
      const set = await UsersRoutes.setAdmin(currentUser._id);
      const admin = await UsersRoutes.getUser(set._id);
      setUser(transformDate(admin));
      setMessage('User successfully setted as Admin');
      showNotification();
    } catch (error) {
      alert(error);
    }
  };

  const handleBlockUser = async e => {
    e.preventDefault();
    try {
      const blocked = await UsersRoutes.blockUser(currentUser._id);
      setUser(transformDate(blocked));
      setMessage('User successfully blocked');
      showNotification();
    } catch (error) {
      alert(error);
    }
  };

  const handleUnblockUser = async e => {
    e.preventDefault();
    try {
      const unblocked = await UsersRoutes.unblockUser(currentUser._id);
      setUser(transformDate(unblocked));
      setMessage('User successfully unblocked');
      showNotification();
    } catch (error) {
      alert(error);
    }
  };

  const handleUpdateUser = async e => {
    e.preventDefault();
    try {
      const upd = await UsersRoutes.updateUser({
        ...editUser,
        _id: currentUser._id,
      });
      setUpdateButton(false);
      setUser(transformDate(upd));
      setEdit({
        email: currentUser.email,
        phone: currentUser.phone,
        country: currentUser.country,
        social: currentUser.social,
      });
      setMessage('User successfully updated');
      showNotification();
    } catch (error) {
      alert(error);
    }
  };

  const openDialog = () => setDialog(true);

  const closeDialog = () => setDialog(false);

  const history = useHistory();

  const handleDeleteUser = async e => {
    e.preventDefault();
    try {
      await UsersRoutes.deleteUser(currentUser);
      history.replace('/admin/dashboard');
    } catch (error) {
      alert(error);
    }
  };

  const {
    type,
    displayName,
    createdAt,
    updatedAt,
    lastActivity,
    description,
    role,
    status,
    social,
    active,
    // NOTE: hide subscription information till it needs
    // planExpired,
    // plan,
    // trialEnds,
  } = currentUser;

  const statusColor = status =>
    status === 'active' ? (
      <Success>
        <p className={classes.cardCategory}>{status}</p>
      </Success>
    ) : status === 'pending' ? (
      <Warning>
        <p className={classes.cardCategory}>{status}</p>
      </Warning>
    ) : (
      <Danger>
        <p className={classes.cardCategory}>{status}</p>
      </Danger>
    );

  const socialBlock = () => {
    if (social && editUser.social) {
      return Object.keys(social).map(key => {
        const value = social[key];
        return (
          editUser.social && (
            <GridContainer key={key}>
              <GridItem xs={6}>
                <p className={classes.socialCat}>{key}</p>
              </GridItem>
              <GridItem xs={6}>
                <TextField
                  onChange={handleChange}
                  name={key}
                  disabled={!value}
                  defaultValue={!value ? 'Not provided' : value}
                ></TextField>
              </GridItem>
            </GridContainer>
          )
        );
      });
    }
  };

  //NOTE: hide subscription information till it needs
  // const trainerInfo = () => {
  //   const today = moment();
  //   const trial = moment(trialEnds) < today;

  //   const trialDate = () =>
  //     trial ? (
  //       <Success>
  //         <p className={classes.social}>{trialEnds}</p>
  //       </Success>
  //     ) : (
  //       <Danger>
  //         <p className={classes.social}>{trialEnds}</p>
  //       </Danger>
  //     );

  //   if (type === 'trainer') {
  //     return (
  //       <GridContainer>
  //         <GridItem xs={12}>
  //           <p className={classes.cardCategory}>Trainer info</p>
  //         </GridItem>
  //         <GridItem xs={6}>
  //           <p className={classes.socialCat}>Plan</p>
  //         </GridItem>
  //         <GridItem xs={6}>
  //           <p className={classes.socialCat}>{plan}</p>
  //         </GridItem>
  //         <GridItem xs={6}>
  //           <p className={classes.socialCat}>Trial ends</p>
  //         </GridItem>
  //         <GridItem xs={6}>{trialDate()}</GridItem>
  //         <GridItem xs={6}>
  //           <p className={classes.socialCat}>Plan Exp.</p>
  //         </GridItem>
  //         <GridItem xs={6}>
  //           <p className={classes.social}>{planExpired}</p>
  //         </GridItem>
  //       </GridContainer>
  //     );
  //   }
  // };

  const activityInfo = () => (
    <GridContainer>
      <GridItem xs={12}>
        <p className={classes.cardCategory}>Activity info</p>
      </GridItem>
      <GridItem xs={6}>
        <p className={classes.socialCat}>Registration Date</p>
      </GridItem>
      <GridItem xs={6}>
        <p className={classes.socialCat}>{createdAt}</p>
      </GridItem>
      <GridItem xs={6}>
        <p className={classes.socialCat}>Last Update Date</p>
      </GridItem>
      <GridItem xs={6}>
        <p className={classes.social}>{updatedAt}</p>
      </GridItem>
      <GridItem xs={6}>
        <p className={classes.socialCat}>Last Activity</p>
      </GridItem>
      <GridItem xs={6}>
        <p className={classes.social}>{lastActivity}</p>
      </GridItem>
    </GridContainer>
  );

  return (
    <div>
      <DeleteAdminDialog
        message={dialogMessage}
        item={item}
        deleteAdminDialogOpen={dialog}
        action={action}
        onCloseDeleteAdminDialog={closeDialog}
        handleDeleteUser={handleDeleteUser}
      />
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card profile>
            <GridContainer>
              <GridItem xs={12} sm={12} md={8}>
                <CardBody style={{ textAlign: 'left' }}>
                  <GridContainer>
                    <GridItem xs={6}>
                      <p className={classes.cardCategory}>Status</p>
                    </GridItem>
                    <GridItem xs={6}>{statusColor(status)}</GridItem>
                    <GridItem xs={6}>
                      <p className={classes.cardCategory}>Email</p>
                    </GridItem>
                    <GridItem xs={6}>
                      {currentUser.email && (
                        <TextField
                          onChange={handleChange}
                          defaultValue={currentUser.email}
                          name='email'
                        ></TextField>
                      )}
                    </GridItem>
                    <GridItem xs={6}>
                      <p className={classes.cardCategory}>Phone</p>
                    </GridItem>
                    <GridItem xs={6}>
                      {currentUser.phone && (
                        <TextField
                          onChange={handleChange}
                          name='phone'
                          defaultValue={currentUser.phone}
                        ></TextField>
                      )}
                    </GridItem>
                    <GridItem xs={6}>
                      <p className={classes.cardCategory}>Country</p>
                    </GridItem>
                    <GridItem xs={6}>
                      {currentUser.country && (
                        <TextField
                          onChange={handleChange}
                          name='country'
                          defaultValue={currentUser.country}
                        ></TextField>
                      )}
                    </GridItem>
                    <GridItem xs={12}>
                      <p className={classes.cardCategory}>Social</p>
                    </GridItem>
                  </GridContainer>
                  {socialBlock()}
                  {/* NOTE: hide subscription information till it needs */}
                  {/* {trainerInfo()} */}
                  {activityInfo()}
                </CardBody>
                <CardFooter />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <CardAvatar profile>
                  <a href='#link' onClick={e => e.preventDefault()}>
                    <img
                      src={showAvatar(currentUser)}
                      alt={currentUser.displayName}
                    />
                  </a>
                </CardAvatar>
                <CardBody profile>
                  <h4 className={classes.description}>{type}</h4>
                  <h3 className={classes.cardTitle}>{displayName}</h3>
                  <p className={classes.description}>{description}</p>

                  {role && role !== 'ADMIN' ? (
                    <>
                      <div>
                        <Button
                          type='button'
                          color='success'
                          onClick={handleSetAdmin}
                        >
                          Set as Admin
                        </Button>
                      </div>
                      <div>
                        {active ? (
                          <Button
                            type='button'
                            color='warning'
                            onClick={handleBlockUser}
                          >
                            Block User
                          </Button>
                        ) : (
                          <Button
                            type='button'
                            color='warning'
                            onClick={handleUnblockUser}
                          >
                            Unblock User
                          </Button>
                        )}
                      </div>
                      <div>
                        <Button
                          onClick={openDialog}
                          type='button'
                          color='danger'
                        >
                          Delete User
                        </Button>
                      </div>
                    </>
                  ) : null}

                  {updateButton && (
                    <div>
                      <Button
                        onClick={handleUpdateUser}
                        type='button'
                        color='primary'
                      >
                        Update User
                      </Button>
                    </div>
                  )}
                </CardBody>
              </GridItem>
            </GridContainer>
          </Card>
        </GridItem>
      </GridContainer>
      <Snackbar
        place='tc'
        color='success'
        icon={CheckCircleOutlineIcon}
        message={message}
        open={tc}
        closeNotification={() => setAlert(false)}
        close
      />
    </div>
  );
};

UserProfile.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = ({ admin }) => ({ admin });
export default connect(mapStateToProps)(withStyles(styles)(UserProfile));

//TODO ADD FUNCTIONS TO DELETE AND UPDATE USER
